import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PageTemplate2 from "../templates/page2"

const DonatePage = () => {
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { title, coverImage, imageCrop },
        html,
      },
    },
  } = useStaticQuery(
    graphql`
      {
        file(name: { eq: "donate" }) {
          childMarkdownRemark {
            frontmatter {
              title
              coverImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              imageCrop
            }
            html
          }
        }
      }
    `
  )

  return (
    <PageTemplate2 title={title} coverImage={coverImage} imageCrop={imageCrop}>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </PageTemplate2>
  )
}

export default DonatePage